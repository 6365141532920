
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    name: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    code: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    tenantDomain: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    startTime: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    endTime: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    status: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
}
export default{
    mainRules
}