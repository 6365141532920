import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { parmsArray } from '@/config/data'

export const shop = [{
    type: 'input',
    name: t('tenant.text3'),
    prop: 'name',
},{
    type: 'input',
    name: t('tenant.text5'),
    prop: 'tenantDomain',
},{
    type: 'select',
    name: t('tenant.text8'),
    prop: 'status',
    isParms: parmsArray[9]
},]