<template>
    <div class="execution">
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud :page="page" :data="page.list" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange"
            :option="tableOption1">
            <template #menu-left>
                <el-button type="primary" @click="openView(0)" >{{$t("button.add")}} </el-button>
            </template>
            <template #caozuo="scope" >
                <el-button text type="primary" size="small" @click="copyfun(scope.row)" v-if="userStore().getIsPlatform">{{$t("button.getLine")}} </el-button>
                <el-button text type="primary" size="small"   @click="openView(1,scope.row)">{{$t("button.update")}} </el-button>
                <el-button text type="primary" size="small"   @click="rowDel(scope.row)">{{$t("button.del")}} </el-button>
            </template>
            <template #startTime="scope">
                {{turnTimeFun1(scope.row.startTime)}}
            </template>
            <template #endTime="scope">
                {{turnTimeFun1(scope.row.endTime)}}
            </template>
            <!-- <template #status="scope">
                {{getDataAboutText(9,scope.row.status)}}
            </template> -->
            <template #status ="scope">
                <el-switch v-model="scope.row.status" :inactive-value='valueArray[1].value' :active-value='valueArray[0].value' @change='enableFun(scope.row)'/>
            </template>
        </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun' @closeFun='dialogFormVisible = false'/>
            <template #footer>
                <el-button    @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun(form_)">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import {tableOption} from "@/const/crud/tenant";
    // import Api_ from '@/api/index' TmFO
    import bindView from './bind.vue'
    import { shop } from '@/const/from/platform/shop'
    import fromSearch from '@/components/fromSearch.vue'
    import { turnTimeFun1 } from '@/utils/util.js'
    import { getDataStyle } from '@/utils/dataBind/parmsView'  
    import Api_ from '@/api/index'
    import { ElNotification,ElMessage } from 'element-plus'
    import website from '@/config/website'
    import { userStore } from '@/store/user'

    import mixins from '@/views/mixins/page'
    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['shopList',['delObjShop','id']])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,rowDel,successFun} = pageObj
    const isStyle = ref(0)
    const isStyleText = [$t("button.add"),$t("button.update")]
    const object_ = ref(shop);
    const bindView_ = ref(null);

    const tableOption1 = ref(null);tableOption1.value = tableOption
    const openView = (num,parm)=>{
        isStyle.value = num
        dialogFormVisible.value = true
        nextTick(()=>{
            bindView_.value.init_(num,parm)
        })
    }
    const completeFun = () => {
        bindView_.value.completeFun()
    }
    const valueArray = ref([])
    getDataStyle('status_type',(e)=>{valueArray.value = e;})
    const enableFun = (e)=>{
        if(e.id){
            listLoading.value = true
            Api_.putObjShop({status:e.status,id:e.id}).then(res=>{
                listLoading.value = false
                if(res.code === 0){
                    ElNotification({
                        message: e.status === '0'?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                    getList()
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }
    }
    const copyfun = (data) => {
        const copyText = website.https + '?tenantId=' + window.btoa(data.id)
        const input = document.createElement('input');
        input.style.position = 'fixed';
        input.style.opacity = 0;
        input.value = copyText;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        ElMessage({
            message: '复制成功',
            type: 'success',
        })
    }
    getList(1)

</script>
