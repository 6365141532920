import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
// import Api_ from '@/api/index'

// const validateTenantCode = (rule, value, callback) => {
//     let obj = {'name': value}
//     Api_.getObjShop(obj).then(response => {
//         const result = response.data
//         if (window.boxType === 'edit') {
//             if (result && result.length !== 0 && result[0].id !== window.boxID) {
//                 callback(new Error(t('tenant.rules1')))
//             } else {
//                 callback()
//             }
//         }else{
//             if (result && result.length !== 0) {
//                 callback(new Error(t('tenant.rules1')))
//             } else {
//                 callback()
//             }
//         }
//     })
// }

// const validateTenantDomain = (rule, value, callback) => {
//   let obj = {'tenantDomain': value}
//   Api_.getObjShop(obj).then(response => {
//     const result = response.data
//     if (window.boxType === 'edit') {
//         if (result && result.length !== 0 && result[0].id !== window.boxID) {
//             callback(new Error(t('tenant.rules2')))
//         } else {
//             callback()
//         }
//     }else{
//         if (result && result.length !== 0) {
//           callback(new Error(t('tenant.rules2')))
//         } else {
//           callback()
//         }

//     }
//   })
// }

export const tableOption = {
  border: true,
  height: 'auto',
  stripe: true,
  menuAlign: 'center',
  searchMenuSpan: 6,
  align: 'center',
  columnBtn: false,
  addBtn: false,
  editBtn: false,
  delBtn: false,
  refreshBtn: false,
  menu:false,
  column: [
    {
      label: t('tenant.text3'),
      prop: 'name',
    },
    {
      label: t('tenant.text4'),
      prop: 'code',
    },
    {
      label: t('tenant.text5'),
      prop: 'tenantDomain',
    },
    {
        width: 170,
      label: t('tenant.text6'),
      prop: 'startTime',
    },
    {
        width: 170,
      label: t('tenant.text7'),
      prop: 'endTime',
    },
    {
      label:  t('tenant.text8'),
      prop: 'status',
      width: 120,
      fixed:'right',
    },
    {
      label: t('tenant.name1'),
      prop: 'address',
    },
    {
      label: t('tenant.name2'),
      prop: 'intro',
    },{
        width: 150,
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
      }
  ]
}
