<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">

            <el-form-item :label='$t("tenant.text3")' prop="name">
                <el-input v-model="fromValue.name" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>

            <el-form-item :label="$t('tenant.text4')" prop="code">
                <el-input v-model="fromValue.code" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>

            <el-form-item :label="$t('tenant.text5')" prop="tenantDomain">
                <el-input v-model="fromValue.tenantDomain" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('button.authority')" prop="tenantDomain">
                <el-button @click="openView">{{$t('button.checks')}}</el-button>
            </el-form-item>
            <el-form-item :label="$t('tenant.text6')" prop="startTime">
                <el-date-picker v-model="fromValue.startTime" type="date"  :placeholder="$t('inputTit.xuanze')" :disabled='style_ === 1' />
            </el-form-item>
            <el-form-item :label="$t('tenant.text7')" prop="endTime">
                <el-date-picker v-model="fromValue.endTime" type="date"  :placeholder="$t('inputTit.xuanze')" :disabled='style_ === 1' />
            </el-form-item>
            <el-form-item :label="$t('tenant.name1')" prop="address">
                <el-input v-model="fromValue.address" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('tenant.name2')" prop="intro">
                <el-input v-model="fromValue.intro" :placeholder="$t('inputTit.shuru')" type="textarea"/>
            </el-form-item>
        </el-form>
        <el-dialog v-model="dialogPermissionVisible" :close-on-click-modal="false" :title="$t('role.text2')" lock-scroll destroy-on-close>
            <div class="dialog-main-tree">
                <el-tree ref="menuTree" :data="treeData" :default-checked-keys="checkedKeys"
                :props="defaultProps" :filter-node-method="filterNode"
                class="filter-tree" node-key="id" highlight-current check-strictly
                show-checkbox  default-expand-all/>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="default" size="small" @click="updatePermession">{{$t('button.enter')}} </el-button>
                    <el-button type="default" size="small" @click="dialogPermissionVisible = false">{{$t('button.close_')}} </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import { resolveAllEunuchNodeIdNew } from '@/utils/util'
    import { ElLoading } from 'element-plus'
    
    const fromValue = reactive({ 
        id: '',name: '',code:'',
        tenantDomain: '',startTime: '',endTime: '',
        status: '',address:'',intro:'',menuIdList:''
    })
    const treeData = ref([])
    const checkedKeys = ref([])
    const dialogPermissionVisible = ref(false)

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)
    const menuTree = ref(null)
    const defaultProps = {
        label: 'name',
        value: 'id'
    }

    const emit = defineEmits(['successFun','closeFun'])

    const filterNode = (value, data)=>{
        if (!value) return true
        return data.label.indexOf(value) !== -1
    }
    const updatePermession = ()=>{
        let menuTree_ = menuTree.value.getCheckedKeys(true)
        fromValue.menuIdList = menuTree_
        //单纯有子节点的情况下，上传他的父节点
        dialogPermissionVisible.value = false
    }
    const loadingList = ref(null)
    const openView = ()=>{//打开权限选着
        loadingList.value = ElLoading.service({
            text: 'Loading'
        })
        Api_.fetchRoleTreeNew_1(fromValue.id).then(response => {
            checkedKeys.value = response.data
            return Api_.fetchMenuTree()
        }).then(response => {
            treeData.value = response.data // 解析出所有的太监节点
            checkedKeys.value = resolveAllEunuchNodeIdNew(treeData.value, checkedKeys.value, [])
            loadingList.value.close()
            dialogPermissionVisible.value = true
        })
    }
    
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    if(obj_.menuIdList === ''){
                        delete obj_.menuIdList
                    }
                    delete obj_.id
                    Api_.addObjShop(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    fromValue.tenantId = fromValue.id
                    Api_.putObjShopNew(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
        }
    }
    defineExpose({init_,completeFun})
</script>
<style lang="scss" scoped>
</style>